import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { apiURL } from '../../constants';
import { useLoading } from '../Hooks/LoadingProvider';
import useErrorData from '../Hooks/ErrorData';
import { ErrorList } from '../Components/ErrorList/ErrorList';
import { useContent } from '../Hooks/ContentProvider';
import { Helmet } from 'react-helmet-async';

interface ResetPasswordForm {
  email: string | null;
  newPassword: string;
  confirmPassword: string;
  token: string | null;
}

export const ResetPasswordPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate()
  const { adjustLoadingCounter } = useLoading();
  const [errorData, setErrors] = useErrorData();
  const { resetPasswordPageContent } = useContent();

  const email = searchParams.get('email')
  const token = searchParams.get('token')

  useEffect(() => {
    adjustLoadingCounter(-1)
    // eslint-disable-next-line
  }, []);

  useEffect(
    () => {
      if (!token) {
        navigate('/')
      }
    }, [navigate, token]
  )

  const [formData, setFormData] = useState<ResetPasswordForm>({
    email,
    newPassword: '',
    confirmPassword: '',
    token,
  });
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setMessage('');
      adjustLoadingCounter(1)
      const response = await axios.post(
        `${apiURL}/api/Account/reset-password`,
        formData
      );
      if (response.status === 200) {
        setMessage(response.data);
        setIsSuccess(true)
        setErrors([])
      }
    } catch (error: any) {
      setErrors(error.response.data.errors)
      console.log(error)
    }
    finally {
      adjustLoadingCounter(-1)
    }
  };

  return (
    <div>
      <Helmet>
        <title>{resetPasswordPageContent.pageTitle}</title>
        <meta name="description" content={resetPasswordPageContent.pageDescription} />
        <meta name="robots" content="noindex" /> 
      </Helmet>
      <h2>{resetPasswordPageContent.resetPasswordButton}</h2>
      {!isSuccess && <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="newPassword">{resetPasswordPageContent.newPasswordLabel}</label>
          <input
            type="password"
            id="newPassword"
            name="newPassword"
            value={formData.newPassword}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="confirmPassword">{resetPasswordPageContent.confirmPasswordLabel}</label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
          />
        </div>
        <button type="submit">{resetPasswordPageContent.resetPasswordButton}</button>
      </form>}
      {message && <p>{message}</p>}
      <ErrorList errors={errorData} />
    </div>
  );
};
