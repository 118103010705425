import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { LoadingSpinner } from "seb-components-library";
import "../../App.scss";

interface LoadingContextValue {
  adjustLoadingCounter: (adjustment: number) => void;
}

const LoadingContext = createContext<LoadingContextValue | undefined>(
  undefined
);

export const useLoading = (): LoadingContextValue => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading must be used within a LoadingProvider");
  }
  return context;
};

export const LoadingProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [loadingCounter, setLoadingCounter] = useState(0);

  const adjustLoadingCounter = useCallback((adjustment: number) => {
    setLoadingCounter((prevCounter) => {
      const newCounter = Math.max(prevCounter + adjustment, 0);
      return newCounter;
    });
  }, []);

  useEffect(() => {
    if (loadingCounter > 0) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [loadingCounter]);

  return (
    <LoadingContext.Provider value={{ adjustLoadingCounter }}>
      {loadingCounter > 0 && (
        <div className="loading-overlay">
          <div className="spinner-wrapper">
            <LoadingSpinner />
          </div>
        </div>
      )}
      {children}
    </LoadingContext.Provider>
  );
};
