import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { LoadingProvider } from "./Shared/Hooks/LoadingProvider";
import { AuthProvider } from "./Shared/Hooks/AuthProvider";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { googleAuthClientId, googleMapsKey } from "./constants";
import { GpsLocationProvider } from "./Shared/Hooks/GpsLocationProvider";
import { APIProvider } from "@vis.gl/react-google-maps";
import App from "./App";
import React from "react";
import { ContentProvider } from "./Shared/Hooks/ContentProvider";
import { getLanguageFromDomain } from "./Shared/Helpers/getLanguageFromDomain";

const domain = window.location.host;
const language = getLanguageFromDomain(domain);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <LoadingProvider>
    <BrowserRouter>
      <GoogleOAuthProvider clientId={googleAuthClientId}>
        <APIProvider apiKey={googleMapsKey!} language={language}>
          <AuthProvider>
            <GpsLocationProvider>
              <ContentProvider language={language}>
                <App />
              </ContentProvider>
            </GpsLocationProvider>
          </AuthProvider>
        </APIProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  </LoadingProvider>
  // </React.StrictMode>
);
