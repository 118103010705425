import { defaultMapZoom, mapId, minZoom } from "../../../constants";
import { mapContainerStyle } from "../../Data/InitialData";
import { InfoWindow, Map, MapEvent, useMap } from "@vis.gl/react-google-maps";
import { IMapMarkers } from "../../Interfaces";
import { CustomMarker } from "../CustomMarkers/CustomMarker";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Marker, MarkerClusterer } from "@googlemaps/markerclusterer";
import { LoadingSpinner } from "seb-components-library";
import { Link } from "react-router-dom";


interface ITrainingSpotsMapProps {
  lat: number;
  lng: number;
  trainingSpotsLocationData: IMapMarkers[];
  selectedMarker: IMapMarkers | null;
  mapLoading: boolean;
  handleInfoWindowClose: () => void;
  onIdle: (e: MapEvent) => void;
  handleMarkerClick: (marker: IMapMarkers) => void;
}
export const TrainingSpotsMap = ({
  lat,
  lng,
  mapLoading,
  trainingSpotsLocationData,
  selectedMarker,
  handleMarkerClick,
  onIdle,
  handleInfoWindowClose,
}: ITrainingSpotsMapProps) => {
  const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});
  const [markersAdded, setMarkersAdded] = useState(0);
  const [markersRendered, setMarkersRendered] = useState(true);
  const map = useMap();

  const setMarkerRef = useCallback((marker: Marker | null, key: string) => {
    setMarkers((markers) => {
      if ((marker && markers[key]) || (!marker && !markers[key]))
        return markers;

      if (marker) {
        // A marker is being added
        setMarkersAdded((prev) => prev + 1); // Increment the counter
        return { ...markers, [key]: marker };
      } else {
        // A marker is being removed
        setMarkersAdded((prev) => prev - 1); // Decrement the counter
        const { [key]: _, ...newMarkers } = markers;
        return newMarkers;
      }
    });
  }, []);

  // Use Effect to check when all markers are loaded
  useEffect(() => {
    // Only set mapLoading to false when all markers are added
    if (markersAdded === trainingSpotsLocationData.length && markersAdded > 0) {
      setMarkersRendered(false);
    }
  }, [markersAdded, trainingSpotsLocationData.length]);

  const clusterer = useMemo(() => {
    if (!map) return null;
    return new MarkerClusterer({ map });
  }, [map]);

  useEffect(() => {
    if (!clusterer) return;
    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));
  }, [clusterer, markers]);

  return (
    <Map
      style={mapContainerStyle}
      mapId={mapId}
      defaultCenter={{ lat: lat, lng: lng }}
      zoomControl={true}
      defaultZoom={defaultMapZoom}
      minZoom={minZoom}
      gestureHandling={"greedy"}
      disableDefaultUI={true}
      onIdle={onIdle}
    >
      {trainingSpotsLocationData &&
        trainingSpotsLocationData.map((coord) => (
          <CustomMarker
            key={coord.id}
            coord={coord}
            onClick={handleMarkerClick}
            setMarkerRef={setMarkerRef}
          />
        ))}

      {selectedMarker && selectedMarker?.posX && selectedMarker?.posY && (
        <InfoWindow
          pixelOffset={[0, -30]}
          position={{ lng: selectedMarker?.posX, lat: selectedMarker.posY }}
          onCloseClick={handleInfoWindowClose}
        >
          <Link to={`/${selectedMarker.id}`}>See More</Link>
        </InfoWindow>
      )}
      {(mapLoading || markersRendered) && trainingSpotsLocationData.length > 0 && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(255, 255, 255, 0.6)",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ margin: "auto" }}>
            <LoadingSpinner />
          </div>
        </div>
      )}
    </Map>
  );
};
