import {
  IAddNewTrainingSpotPageContent,
  IForgotPasswordPageContent,
  IGlobalContent,
  IHeaderContent,
  IHomePageContent,
  ILoginPageContent,
  IMyProfilePageContent,
  IMyTrainingSpotsContent,
  IRegisterPageContent,
  ITrainingSpotPage,
  ITrainingSpotTagDictionary,
  ITrainingSpotTypesDictionary,
  ILocationsPageContent,
  ICityInfoContent,
  IGlobalDataPageContent,
  ICountryInfoContent,
  IResetPasswordPage,
  
} from "./IContent";

export function localizedContent(language: string): IGlobalContent {
  const basePath = `./${language}`;

  const homePageContent: IHomePageContent = require(`${basePath}/homePage.json`);
  const loginPage: ILoginPageContent = require(`${basePath}/loginPage.json`);
  const registerPageContent: IRegisterPageContent = require(`${basePath}/registerPage.json`);
  const headerContent: IHeaderContent = require(`${basePath}/header.json`);
  const addNewTrainingSpotPageContent: IAddNewTrainingSpotPageContent = require(
    `${basePath}/addNewTrainingSpot.json`
  );
  const forgotPasswordPageContent: IForgotPasswordPageContent = require(
    `${basePath}/forgotPassword.json`
  );
  const myTrainingSpotsPageContent: IMyTrainingSpotsContent = require(
    `${basePath}/myTrainingSpotsPage.json`
  );
  const myProfilePageContent: IMyProfilePageContent = require(
    `${basePath}/myProfile.json`
  );
  const trainingSpotTagDictionary: ITrainingSpotTagDictionary = require(
    `${basePath}/trainingSpotTags.json`
  );
  const trainingSpotTypesDictionary: ITrainingSpotTypesDictionary = require(
    `${basePath}/trainingSpotTypes.json`
  );
  const trainingSpotPageContent: ITrainingSpotPage = require(
    `${basePath}/trainingSpotPage.json`
  );
  const locationsPageContent: ILocationsPageContent = require(
    `${basePath}/locationsPage.json`
  );
  const cityInfoContent: ICityInfoContent = require(
    `${basePath}/cityInfo.json`
  );
  const globalDataPageContent: IGlobalDataPageContent = require(
    `${basePath}/globalDataPage.json`
  );
  const countryInfoContent: ICountryInfoContent = require(
    `${basePath}/countryInfoContent.json`
  );
  const resetPasswordPageContent: IResetPasswordPage = require(
    `${basePath}/resetPasswordPage.json`
  );

  return {
    homePageContent,
    loginPage,
    registerPageContent,
    headerContent,
    addNewTrainingSpotPageContent,
    forgotPasswordPageContent,
    myTrainingSpotsPageContent,
    myProfilePageContent,
    trainingSpotTagDictionary,
    trainingSpotTypesDictionary,
    trainingSpotPageContent,
    locationsPageContent,
    cityInfoContent,
    globalDataPageContent,
    countryInfoContent,
    resetPasswordPageContent
  };
}
