import { useLayoutEffect, useState } from "react";
import { useLoading } from "../../Hooks/LoadingProvider";
import axios from "axios";
import { apiURL } from "../../../constants";
import useErrorData from "../../Hooks/ErrorData";
import { ErrorList } from "../../Components/ErrorList/ErrorList";
import { CountryData } from "../../Interfaces";
import "./GlobalDataPage.scss";
import { CountryDataContainer } from "../../Components/CountryDataContainer/CountryDataContainer";
import { Helmet } from "react-helmet-async";
import { useContent } from "../../Hooks/ContentProvider";

export const GlobalDataPage = () => {
  const { adjustLoadingCounter } = useLoading();
  const [errorData, setErrors] = useErrorData();
  const [globalData, setGlobalData] = useState<CountryData[]>([]);
  const { globalDataPageContent } = useContent();

  useLayoutEffect(() => {
    adjustLoadingCounter(1);
    async function fetchData() {
      try {
        setErrors([]);
        const response = await axios.get<CountryData[]>(
          `${apiURL}/api/LocationStats/city-counts-by-country`
        );
        setGlobalData(response.data);
      } catch (error: any) {
        setErrors(error);
        console.error("Error fetching global data:", error);
      } finally {
        adjustLoadingCounter(-1);
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, []); // Empty dependency array to run only once

  return (
    <>
      <h1>{globalDataPageContent.header}</h1>
      <Helmet>
        <title>{globalDataPageContent.pageTitle}</title>
        <meta
          name="description"
          content={globalDataPageContent.pageDescription}
        />
        <meta name="keywords" content={globalDataPageContent.pageKeywords} />
      </Helmet>
      <div className="globalDataContainer">
        {globalData.map((x: CountryData) => {
          return <CountryDataContainer key={x.countryCode} countryData={x} />;
        })}
      </div>
      <ErrorList errors={errorData} />
    </>
  );
};
