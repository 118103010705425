import { useEffect, useState } from "react";
import axios from "axios";
import { useLoading } from "../../Hooks/LoadingProvider";
import { apiURL, replacementString } from "../../../constants";
import "./CountryInfo.scss";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { ErrorList } from "../ErrorList/ErrorList";
import useErrorData from "../../Hooks/ErrorData";
import { useContent } from "../../Hooks/ContentProvider";

interface ICountryInfoProps {
  country_code: string;
}

interface City {
  name: string;
  asciiName: string;
  count: number;
}

interface CountryData {
  countryCode: string;
  formattedCountryName: string;
  cities: City[];
}

export const CountryInfo = ({ country_code }: ICountryInfoProps) => {
  const { adjustLoadingCounter } = useLoading();
  const [countryData, setCountryData] = useState<CountryData | null>(null);
  const [errors, setErrors] = useErrorData();
  const { countryInfoContent } = useContent();

  useEffect(() => {
    const fetchCountryData = async () => {
      setErrors([]);
      try {
        const response = await axios.get<CountryData>(
          `${apiURL}/api/LocationStats/city-counts-by-country/${country_code}`
        );
        setCountryData(response.data);
      } catch (error: any) {
        console.error("Error fetching country data:", error);
        setErrors(error);
      } finally {
        adjustLoadingCounter(-1);
      }
    };

    fetchCountryData();
    //eslint-disable-next-line
  }, [adjustLoadingCounter]);

  return (
    <div>
      <Helmet>
        <title>{countryInfoContent.pageTitlePreload}</title>
        {countryData && (
          <>
            <title>
              {countryInfoContent.pageTitle.replace(
                replacementString,
                countryData.formattedCountryName
              )}
            </title>
            <meta
              name="description"
              content={countryInfoContent.pageDescription.replaceAll(
                replacementString,
                countryData.formattedCountryName
              )}
            />
            <meta
              name="keywords"
              content={countryInfoContent.pageKeywords.replaceAll(
                replacementString,
                countryData.formattedCountryName
              )}
            />
          </>
        )}
      </Helmet>
      {countryData && (
        <>
          <h1>
            {countryInfoContent.header.replace(
              replacementString,
              countryData.formattedCountryName
            )}
          </h1>
          <div className="cityLinksContainer">
            {countryData.cities.map((x) => (
              <Link
                key={x.asciiName}
                to={`/locations/${countryData.countryCode.toLowerCase()}/${x.asciiName}`}
              >
                {x.name} ({x.count})
              </Link>
            ))}
          </div>
        </>
      )}
      <ErrorList errors={errors} />
    </div>
  );
};

export default CountryInfo;
