import React, { useLayoutEffect, useState } from 'react';
import axios from 'axios';
import { apiURL, minimumLoadingDuration } from '../../constants';
import { useLoading } from '../Hooks/LoadingProvider';
import useErrorData from '../Hooks/ErrorData';
import { ErrorList } from '../Components/ErrorList/ErrorList';
import { useContent } from '../Hooks/ContentProvider';
import { Helmet } from 'react-helmet-async';

export const ForgotPasswordPage = () => {
    const [email, setEmail] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const { adjustLoadingCounter } = useLoading();
    const [resetEmailSuccess, setResetEmailSuccess] = useState<boolean>(false)
    const [errorData, setErrors] = useErrorData();
    const { forgotPasswordPageContent } = useContent()

    const handleChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        try {
            adjustLoadingCounter(1)
            setMessage('');
            const response = await axios.post(
                `${apiURL}/api/Account/forgot-password`,
                { email }
            );
            if (response.status === 200) {
                setResetEmailSuccess(true)
                setMessage(response.data);
            }
        } catch (error: any) {
            setErrors(error.response.data.errors)
            console.log(error)
        }
        finally {
            adjustLoadingCounter(-1)
        }
    };

    useLayoutEffect(() => {
        adjustLoadingCounter(1);
        setTimeout(() => {
        adjustLoadingCounter(-1);
        }, minimumLoadingDuration);
    }, [adjustLoadingCounter]);

    return (
        <div>
            <Helmet>
                <title>{forgotPasswordPageContent.pageTitle}</title>
                <meta name="description" content={forgotPasswordPageContent.pageDescription} />
                <meta name="robots" content="noindex" /> 
            </Helmet>
            <h2>{forgotPasswordPageContent.header}</h2>
            {!resetEmailSuccess && <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="email">{forgotPasswordPageContent.emailLabel}</label>
                    <input
                        id="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                    />
                </div>
                <button type="submit">{forgotPasswordPageContent.submitButton}</button>
            </form>}
            {message && <p>{message}</p>}
            <ErrorList errors={errorData} />
        </div>
    );
};
