import { Link } from "react-router-dom";
import { CountryData } from "../../Interfaces";
import "./CountryDataContainer.scss";

interface ICountryDataContainerProps {
  countryData: CountryData;
}

export const CountryDataContainer = ({ countryData }: ICountryDataContainerProps) => {
  return (
    <div className="container">
      <p>{countryData.formattedCountryName}</p>
      <ul>
        {countryData.cities.map((x) => {
          return (
            <li key={x.asciiName}>
              <Link to={`/locations/${countryData.countryCode.toLowerCase()}/${x.asciiName}`}>
                {x.name} ({x.count})
              </Link>
            </li>
          );
        })}
      </ul>
      <div className="container__countryLink">
        <p>View all records for:</p>
        <Link to={`/locations/${countryData.countryCode.toLowerCase()}`}>
          {`${countryData.countryCode}`}
        </Link>
      </div>
    </div>
  );
};
