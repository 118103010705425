import { convertMetersToKilometers } from "../../Helpers/convertMetersToKilometers";
import { handleOutsideIndex } from "../../Helpers/handleOutsideIndex";
import { TrainingSpotPicture } from "../TraingSpotPicture/TrainingSpotPicture";
import "./TrainingSpot.scss";
import { ITrainingSpot } from "../../Interfaces";
import { Link } from "react-router-dom";

export interface ITrainingSpotProps {
  trainingSpot: ITrainingSpot;
  editable?: boolean;
  onDeleteClick?: (trainingSpotId: string) => void;
  onEditClick?: (trainingSpotId: string) => void;
  handleShowOnMap?: (trainingSpotId: string) => void;
  ratingLabel: string;
  distanceFromYoulabel?: string;
  editLabel?: string
  viewLabel: string
  showOnMapLabel?: string
  deleteLabel?: string
}

export const TrainingSpot = ({
  trainingSpot,
  editable = false,
  onDeleteClick,
  onEditClick,
  handleShowOnMap,
  distanceFromYoulabel,
  ratingLabel,
  editLabel,
  viewLabel,
  deleteLabel,
  showOnMapLabel
}: ITrainingSpotProps) => {

  const imageArray = trainingSpot.trainingSpotPictureIds;
  const pictureIndex = handleOutsideIndex(imageArray, trainingSpot.mainPictureIndex);
  const pictureSrc = imageArray[pictureIndex];
  return (
    <div className="trainingSpotContainer">
      <div className="trainingSpotContainer__title">
        <div>
          <p>{trainingSpot.title}</p>
          <p>{ratingLabel} {trainingSpot.currentRating}</p>
        </div>
        <div>
          {!editable && trainingSpot.distance !== null && distanceFromYoulabel && (
            <p>{convertMetersToKilometers(trainingSpot.distance)} {distanceFromYoulabel}</p>
          )}
        </div>
      </div>
      <div className="trainingSpotContainer__pictures">
        {imageArray.length > 0 && <TrainingSpotPicture pictureId={pictureSrc} />}
        <div className="trainingSpotContainer__pictures__buttons">
          {editable && (
            <>
              <button onClick={() => onDeleteClick && onDeleteClick(trainingSpot.id)}>
                {deleteLabel}
              </button>
              <button onClick={() => onEditClick && onEditClick(trainingSpot.id)}>{editLabel}</button>
            </>
          )}
          <Link to={`/${trainingSpot.id}`}>{viewLabel}</Link>
          {handleShowOnMap && showOnMapLabel && (
            <button onClick={() => handleShowOnMap(trainingSpot.id)}>{showOnMapLabel}</button>
          )}
        </div>
      </div>
    </div>
  );
};
