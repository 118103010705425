import React from 'react';
import './Footer.scss';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer>
      <p>© {new Date().getFullYear()} Sports Advisor. All rights reserved.</p>
      <Link to={'/locations'}>Show Global Data</Link>
    </footer>
  );
};

export default Footer;
