import { useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useContent } from "../../Hooks/ContentProvider";
import useErrorData from "../../Hooks/ErrorData";
import { ErrorList } from "../../Components/ErrorList/ErrorList";
import CountryInfo from "../../Components/CountryInfo/CountryInfo";
import { ICityInfo } from "../../Interfaces";
import { CityInfo } from "../../Components/CityInfo/CityInfo";
import { useLoading } from "../../Hooks/LoadingProvider";
import { locationServiceBaseURL } from "../../../constants";

export const CityInfoPage = () => {
  const { country_code, param1, param2 } = useParams();
  const [cityInfo, setCityInfo] = useState<ICityInfo>();
  const [errorData, setErrors] = useErrorData();
  const { locationsPageContent } = useContent();
  const { adjustLoadingCounter } = useLoading();

  useLayoutEffect(() => {
    adjustLoadingCounter(2);
    const fetchCityInfo = async () => {
      setErrors([]);
      setCityInfo(undefined); // Clear previous cityInfo before fetching
      try {
        let url = `${locationServiceBaseURL}/city-info-by-ascii`;
        let params: {
          country_code: string;
          admin_area?: string;
          city_name?: string;
          language: string;
        } = {
          country_code: country_code!.toUpperCase(),
          language: locationsPageContent.language,
        };

        if (param2) {
          // If param2 exists, it means the URL is /locations/:country_code/:admin_area/:city_name
          params.admin_area = param1!;
          params.city_name = param2!;
        } else if (param1) {
          // If param1 exists, it means the URL is /locations/:country_code/:city_name
          params.city_name = param1!;
        }

        const response = await axios.get<ICityInfo>(url, { params });
        setCityInfo(response.data);
      } catch (error: any) {
        setErrors(error);
      } finally {
        (country_code || param1) && adjustLoadingCounter(-1)
        !country_code && !param1 && adjustLoadingCounter(-2)
      }
    };

    if (country_code) {
      fetchCityInfo();
    }
    return () => {
      adjustLoadingCounter(-2);
    };
    // eslint-disable-next-line
  }, [param1, adjustLoadingCounter]);

  return (
    <div>
      <h1>Locations Page</h1>
      {!param1 && country_code && <CountryInfo country_code={country_code} />}
      {cityInfo && param1 && <CityInfo cityInfo={cityInfo} />}
      {errorData && <ErrorList errors={errorData} />}
    </div>
  );
};
