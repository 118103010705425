import { useEffect, useState } from "react";
import { useLoading } from "../Hooks/LoadingProvider";
import axios from "axios";
import { apiURL } from "../../constants";
import useErrorData from "../Hooks/ErrorData";
import { ErrorList } from "../Components/ErrorList/ErrorList";
import { useAuth } from "../Hooks/AuthProvider";
import { Helmet } from "react-helmet-async";

export const AdminArea = () => {
    const { adjustLoadingCounter } = useLoading();
    const [errorData, setErrors] = useErrorData();
    const { token } = useAuth();
    const [message, setMessage] = useState<string>('')

    const fetchAdminTest = async () => {
        try {
            let result = await axios.get(`${apiURL}/api/Admin/test`, {
                headers: {
                    Authorization: token
                }
            });
            console.log('result')
            console.log(result)
            if (result.status === 200) {
                setMessage('Welcome, Admin')
            }
        } catch (error: any) {
            console.error(error.response.status);
            if (error.response.status === 401) {
                setMessage('You are not logged in')
            }
            if (error.response.status === 403) {
                setMessage('You are not allowed')
            }
            console.error(error);
            setErrors(error);
        } finally {
            adjustLoadingCounter(-1)
        }
    }

    useEffect(
        () => {
            fetchAdminTest()
            // eslint-disable-next-line
        }, []
    )

    return (
        <>
            <Helmet>
                <title>Admin Area | Sports Advisor</title>
            </Helmet>
            <ErrorList errors={errorData} />
            <p>{message}</p>
        </>
    )
}