export const apiURL = process.env.REACT_APP_API_URL
export const locationServiceBaseURL = process.env.REACT_APP_LOCATION_SERVICE_BASE_URL
export const googleAuthClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID!
export const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS;
export const mapId = process.env.REACT_APP_MAP_ID;

export const replacementString = '###'
export const ipApiUrl = 'https://ipapi.co/json/'
export const defaultLocationName = 'your area'
export const locationError = 'Unable to access accurate GPS location'
export const minimumLoadingDuration = 500
export const defaultMapZoom = 10
export const minZoom = 8
export const roleClaim = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
export const adminRole = 'Administrator'
export const skipTakeDefault = 6