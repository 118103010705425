import React, { useState, useCallback } from "react";
import { ComboBoxAutoComplete } from "seb-components-library";
import { locationServiceBaseURL, minimumLoadingDuration } from "../../constants";
import { debounce } from "../Helpers/debounce";
import { formatLocationName } from "../Helpers/formatLocationName";

interface Props {
  onFocus: () => void;
  onPlaceSelect: (selectedItem: ISearchItem) => void;
  placeholder: string;
  emptyResultMessage: string;
  language: string;
}

interface ISearchItem {
  geonameId: number;
  name: any;
  countryCode: string;
  latitude: number;
  longitude: number;
}

export const AutocompleteCustomHybrid = ({
  onFocus,
  onPlaceSelect,
  placeholder,
  emptyResultMessage,
  language
}: Props) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [dataSource, setDataSource] = useState<ISearchItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);

  // eslint-disable-next-line
  const fetchPredictions = useCallback(
    debounce(async (inputValue: string) => {
      if (!inputValue.trim()) {
        setDataSource([]);
        setIsLoading(false); // Ensure loading stops if input is cleared
        return;
      }

      setIsLoading(true); // Indicate fetching data

      try {
        const response = await fetch(
          `${locationServiceBaseURL}/search?language=${language}&keywords=${encodeURIComponent(inputValue)}`
        );
        const data = await response.json();
        setDataSource(data);
      } catch (error) {
        console.error("Error fetching predictions:", error);
        setDataSource([]);
      } finally {
        setIsLoading(false); // Stop loading after fetch
      }
    }, minimumLoadingDuration),
    [language]
  );

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsTyping(true); // Set typing to true when input changes
      setInputValue(e.target.value);
      fetchPredictions(e.target.value);
    },
    [fetchPredictions]
  );

  const onItemClick = useCallback(
    (_e: React.MouseEvent, selectedItem: ISearchItem) => {
      setIsTyping(false); // Stop typing when an item is selected
      onPlaceSelect(selectedItem);
      setInputValue(selectedItem.name[language].city);
      setDataSource([]);
    },
    [onPlaceSelect, language]
  );

  return (
    <ComboBoxAutoComplete
      onFocus={onFocus}
      placeholder={placeholder}
      dataSource={dataSource}
      listItemRender={(item) => (
        <>
          {formatLocationName(item.name[language])}
        </>
      )}
      onItemClick={onItemClick}
      inputValue={inputValue}
      onInputChange={onInputChange}
      isLoading={isLoading} // Show loading spinner based on fetch state
      ariaKey="geonameId"
      selectedValue={null}
      EmptyResultMessage={isTyping ? "Searching..." : emptyResultMessage} // Display searching message when typing
      buttonDropDownAriaKey="geonameId"
      style={{ zIndex: "1", margin: "0.25rem" }}
    />
  );
};
